import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getNavList } from "./helper/helper";
import { Box, Tabs, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getCognitoUserRole } from "../../helper/userHelper";

const NavBarHeader = (props) => {
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const list =
    undefined !== user.attributes ? getNavList(getCognitoUserRole(user)) : [];

  const isActive = (link) => location.pathname === link;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="#013C44"
    >
      <Tabs
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={{
          style: { backgroundColor: "#ffffff" },
        }}
      >
        {list.map((item) => (
          <Tab
            key={item.link}
            label={item.name}
            value={item.link}
            component={Link}
            to={item.link}
            style={{
              textTransform: "none",
              fontWeight: isActive(item.link) ? "bold" : "normal",
              color: "#ffffff",
              minWidth: "auto", // Keeps tabs compact
              padding: "8px 16px", // Adjust padding for better spacing
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default NavBarHeader;
