import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Link,
  Button,
  TextField,
  Tab,
  Tabs,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  exeption_close,
  getUser,
  loader_add,
  loginConfirmSelectMfa,
  notification_close,
  sendMFASelectionAnswer,
} from "../../../actions/actions";
import { routes } from "../../../route/routes";
import { TabPanel } from "./SetupMFA";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text1: {
    fontSize: 14,
    color: theme.palette.grey[700],
  },
  text2: {
    fontSize: 15,
    color: theme.palette.grey[700],
  },
  label: { fontWeight: 500, fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
}));
const SelectMFAType = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [tab, setTab] = useState(0);

  const user = useSelector((state) => state.user);

  const handleTabChange = (_, value) => {
    setTab(value);
  };

  const sendCode = (type) => {
    dispatch(sendMFASelectionAnswer(user, type, dispatch));
  };

  const back = () => {
    history.push(routes.LOGIN);
    dispatch(getUser(dispatch));
    dispatch(exeption_close());
    dispatch(notification_close());
  };

  const confirm = (type) => {
    dispatch(loginConfirmSelectMfa(user, code, type, history, dispatch));
    dispatch(loader_add());
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        TabIndicatorProps={{
          style: { backgroundColor: "#013C44" },
        }}
        style={{
          fontSize: 14,
          paddingBottom: 10,
        }}
      >
        <Tab
          label="TOTP MFA"
          style={{
            color: tab === 0 ? "#013C44" : "#717680",
            textTransform: "none",
          }}
        />
        <Tab
          label="SMS MFA"
          style={{
            color: tab === 1 ? "#013C44" : "#717680",
            textTransform: "none",
          }}
        />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Code</Typography>
            <TextField
              name="Code"
              variant="outlined"
              className={classes.textField}
              fullWidth
              size="small"
              placeholder="Enter code"
              onChange={(e) => setCode(e.target.value)}
              onKeyUp={(e) => setCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                onClick={(e) => confirm("SOFTWARE_TOKEN_MFA")}
                className={classes.button}
                fullWidth
              >
                Confirm
              </Button>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={(e) => sendCode("SMS_MFA")}
              >
                Send Code
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.label}>Code</Typography>
            <TextField
              name="Code"
              variant="outlined"
              className={classes.textField}
              fullWidth
              size="small"
              placeholder="Enter code"
              onChange={(e) => setCode(e.target.value)}
              onKeyUp={(e) => setCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={(e) => confirm("SMS_MFA")}
              >
                Confirm
              </Button>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <Box display="flex" justifyContent="center" mt={3}>
        <Link
          onClick={back}
          align="center"
          style={{
            fontSize: "0.8rem",
            fontWeight: 600,
            color: "#013C44",
            cursor: "pointer",
          }}
        >
          Back to Sign in
        </Link>
      </Box>
    </>
  );
};

export default SelectMFAType;
