import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Avatar } from "@material-ui/core";

const AccountPopover = ({ logout, user, companyAccess, headerFontColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const firstLetter =
    (user.attributes &&
      ((user.attributes["custom:firstName"] &&
        user.attributes["custom:firstName"].charAt(0).toUpperCase()) ||
        (user.attributes["custom:lastName"] &&
          user.attributes["custom:lastName"].charAt(0).toUpperCase()))) ||
    "U";

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Avatar
          style={{
            backgroundColor:
              companyAccess === "whiteLabel" && headerFontColor
                ? headerFontColor
                : "#E6ECEC", // Match color from image
            color: "#013C44", // Contrast text color
          }}
        >
          {firstLetter}
        </Avatar>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem
          onClick={(e) => {
            handleClose();
            logout(e);
          }}
          style={{ fontSize: "0.85rem", padding: "6px 12px" }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountPopover;
