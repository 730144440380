import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import ExeptionPopup from "./components/popup/ExeptionPopup";
import Notification from "./components/popup/Notification";
import Loader from "./components/popup/Loader";
import {
  getUser,
  getUserCompany,
  setCompanyAccess,
  getCompanyWhiteLabel,
  wlLoader_add,
  signOut,
  wlLoader_remove,
  getWhiteLableSettings,
  setSubdomainStatus,
} from "./actions/actions";
import { getCognitoUserCompanyId } from "./helper/userHelper";
import { Auth } from "@aws-amplify/auth";
import { useLocation, useHistory } from "react-router-dom";
import { getCognitoUserRole, UserRole } from "./helper/userHelper";
import { contentWrapper } from "./css/styles";

const App = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const exeption = useSelector((state) => state.exeption);
  const notification = useSelector((state) => state.notification);
  const loader = useSelector((state) => state.loader);
  const user = useSelector((state) => state.user);
  const companyId = getCognitoUserCompanyId(user);
  const company = useSelector((state) => state.userCompany);
  const companyAccess = useSelector((state) => state.companyAccess);
  const whiteLabel = useSelector((state) => state.whiteLabel);
  const newSubdomain = useSelector(
    (state) => state.newSubdomain?.newSubdomain.subdomain
  );
  const logout = useSelector((state) => state.logoutStatus);
  const isWLLoading = useSelector((state) => state.wlLoader);
  const isSubdomainWrong = useSelector((state) => state.isSubdomainWrong);
  const subdomain = whiteLabel?.subdomain;
  const query = useQuery();
  const history = useHistory();
  const parts = window.location.hostname.split(".");
  const subDomain = parts.shift();
  const firstDotIndex = subDomain.indexOf(".");
  const domain = subDomain.substring(firstDotIndex + 1);
  const userRole = getCognitoUserRole(user);
  const session = query.get("session");
  const styles = contentWrapper();
  const [redirectUrl, setRedirectUrl] = useState("");

  const shouldShowHeaderFooter =
    !location.pathname.includes("multifactor-auth") &&
    user !== "not authenticated" &&
    user?.attributes !== undefined;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const saveUserSession = (session) => {
    const cognitoClientId = session.accessToken.payload.client_id;
    const userName = session.accessToken.payload.username;
    const makeKey = (name) =>
      `CognitoIdentityServiceProvider.${cognitoClientId}.${userName}.${name}`;
    localStorage.setItem(makeKey("accessToken"), session.accessToken.jwtToken);
    localStorage.setItem(makeKey("idToken"), session.idToken.jwtToken);
    localStorage.setItem(makeKey("refreshToken"), session.refreshToken.token);
    localStorage.setItem(makeKey("clockDrift"), session.clockDrift);
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${cognitoClientId}.LastAuthUser`,
      userName
    );
  };

  const replace = (subdomain) => {
    Auth.currentSession()
      .then((session) => {
        const sessonJSON = JSON.stringify(session);
        if (
          domain === "portal" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin
        ) {
          dispatch(wlLoader_add());
          window.location.replace(
            `https://${subdomain}.esimplifysolutions.us/?session=${sessonJSON}`
          );
          dispatch(signOut(dispatch, history));
        }
        if (
          domain === "staging" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin
        ) {
          dispatch(wlLoader_add());
          window.location.replace(
            `https://${subdomain}.staging.esimplifysolutions.us/?session=${sessonJSON}`
          );
          dispatch(signOut(dispatch, history));
        }
        if (
          domain === "beta" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin
        ) {
          dispatch(wlLoader_add());
          window.location.replace(
            `https://${subdomain}.beta.esimplifysolutions.us/?session=${sessonJSON}`
          );
          dispatch(signOut(dispatch, history));
        }
        if (
          domain === "localhost" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin
        ) {
          dispatch(wlLoader_add());
          window.location.replace(
            `http://${subdomain}.test.localhost:3000/?session=${sessonJSON}`
          );
          dispatch(signOut(dispatch, history));
        }

        if (
          parts[0] === "esimplifysolutions" &&
          subdomain &&
          domain !== "portal" &&
          domain !== "staging" &&
          domain !== "beta" &&
          domain !== "localhost"
        ) {
          window.location.replace(
            `https://${subdomain}.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (parts[0] === "staging" && subdomain) {
          window.location.replace(
            `https://${subdomain}.staging.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (parts[0] === "beta" && subdomain) {
          window.location.replace(
            `https://${subdomain}.beta.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (parts[0] === "test" && subdomain) {
          window.location.replace(
            `http://${subdomain}.test.localhost:3000/?session=${sessonJSON}`
          );
        }
      })
      .catch((e) => {
        if (e === "No current user") {
          if (
            parts[0] === "esimplifysolutions" ||
            parts[0] === "portal" ||
            parts[0] === "staging" ||
            parts[0] === "beta" ||
            parts[0] === "test"
          ) {
            return;
          }
          dispatch(wlLoader_remove());
        }
        console.log(e);
      });
  };

  const createRedirectUrl = (subdomain) => {
    Auth.currentSession()
      .then((session) => {
        const sessonJSON = JSON.stringify(session);
        if (
          domain === "portal" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `https://${subdomain}.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (
          domain === "staging" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `https://${subdomain}.staging.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (
          domain === "beta" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `https://${subdomain}.beta.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (
          domain === "localhost" &&
          subdomain &&
          userRole !== UserRole.SuperAdmin &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `http://${subdomain}.test.localhost:3000/?session=${sessonJSON}`
          );
        }

        if (
          parts[0] === "esimplifysolutions" &&
          subdomain &&
          domain !== "portal" &&
          domain !== "staging" &&
          domain !== "beta" &&
          domain !== "localhost" &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `https://${subdomain}.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (
          parts[0] === "staging" &&
          subdomain &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `https://${subdomain}.staging.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (
          parts[0] === "beta" &&
          subdomain &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `https://${subdomain}.beta.esimplifysolutions.us/?session=${sessonJSON}`
          );
        }
        if (
          parts[0] === "test" &&
          subdomain &&
          companyAccess === "whiteLabel"
        ) {
          setRedirectUrl(
            `http://${subdomain}.test.localhost:3000/?session=${sessonJSON}`
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const replaceToPortal = () => {
    if (
      parts[0] === "esimplifysolutions" &&
      subdomain &&
      domain !== "portal" &&
      domain !== "staging" &&
      domain !== "beta" &&
      domain !== "localhost" &&
      user !== "not authenticated"
    ) {
      setRedirectUrl(`https://portal.esimplifysolutions.us/`);
    }
    if (parts[0] === "staging" && user !== "not authenticated") {
      setRedirectUrl(`https://staging.esimplifysolutions.us/`);
    }
    if (parts[0] === "beta" && user !== "not authenticated") {
      setRedirectUrl(`https://beta.esimplifysolutions.us/`);
    }
    if (parts[0] === "localhost") {
      setRedirectUrl(`http://localhost:3000/`);
    }
  };
  useEffect(() => {
    dispatch(wlLoader_add());
  }, [dispatch]);

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      domain === "localhost" ||
      domain === "esimplifysolutions" ||
      domain === "portal" ||
      domain === "staging"
    )
      return;
    if (
      domain &&
      (parts[0] === "esimplifysolutions" ||
        parts[0] === "portal" ||
        parts[0] === "staging" ||
        parts[0] === "beta" ||
        parts[0] === "test")
    ) {
      dispatch(getWhiteLableSettings(domain, dispatch));
    }
  }, [dispatch, domain]);

  useEffect(() => {
    if (whiteLabel) {
      let validName = whiteLabel.companyName || whiteLabel.name;
      document.title = validName || "esimplify";
      document.getElementById("headIcon").href =
        whiteLabel.logoUrl || "/favicon.ico";
    }
  }, [whiteLabel]);

  useEffect(() => {
    if (
      domain &&
      (parts[0] === "esimplifysolutions" ||
        parts[0] === "portal" ||
        parts[0] === "staging" ||
        parts[0] === "beta" ||
        parts[0] === "test" ||
        parts[0] === "localhost" ||
        domain === "localhost")
    ) {
      if (companyAccess === "free" || companyAccess === "paid") {
        if (
          domain === "localhost" ||
          domain === "esimplifysolutions" ||
          domain === "portal" ||
          domain === "staging"
        )
          return;
        replaceToPortal();
        dispatch(setSubdomainStatus(true));
      }

      if (domain && subdomain && domain !== subdomain) {
        if (subdomain && companyAccess === "whiteLabel") {
          createRedirectUrl(subdomain);
        }

        if (companyAccess !== "whiteLabel" && companyAccess !== null) {
          replaceToPortal();
        }
        dispatch(setSubdomainStatus(true));
      }
    }
  }, [dispatch, domain, subdomain, companyAccess]);

  useEffect(() => {
    if (session) {
      const parsedSession = JSON.parse(session);
      saveUserSession(parsedSession);
    }
  }, []);

  useEffect(() => {
    if (user === null) {
      dispatch(getUser(dispatch));
    }
  }, [dispatch, user]);

  if (user && user.Session && user.signInUserSession === null) {
    dispatch(wlLoader_remove());
  }
  if (
    (domain === "portal" ||
      domain === "staging" ||
      domain === "beta" ||
      domain === "localhost") &&
    user &&
    user !== "not authenticated" &&
    user.Session === null &&
    companyAccess !== "whiteLabel" &&
    companyAccess !== null
  ) {
    dispatch(wlLoader_remove());
  }

  if (user && user === "not authenticated") {
    dispatch(wlLoader_remove());
  }

  useEffect(() => {
    if (company === null && companyId) {
      dispatch(getUserCompany(companyId, dispatch));
    }
    if (company !== null) {
      dispatch(setCompanyAccess(company.accessType));
    }
    if (company && companyId && companyId !== undefined) {
      if (companyAccess === "whiteLabel") {
        dispatch(
          getCompanyWhiteLabel(companyId, companyAccess, whiteLabel, dispatch)
        );
      }
    }
  }, [dispatch, companyId, company, companyAccess]);

  useEffect(() => {
    if (
      subdomain &&
      userRole !== UserRole.SuperAdmin &&
      companyAccess === "whiteLabel" &&
      (domain === "portal" ||
        domain === "staging" ||
        domain === "beta" ||
        domain === "localhost")
    ) {
      replace(subdomain);
    }
  }, [companyAccess, subdomain]);

  useEffect(() => {
    if (companyAccess === "whiteLabel" && newSubdomain !== subdomain) {
      replace(newSubdomain);
    }
  }, [newSubdomain, subdomain, companyAccess]);

  useEffect(() => {
    if (logout) {
      dispatch(wlLoader_remove());
    }
  }, [dispatch, logout]);

  /* eslint-enable react-hooks/exhaustive-deps */

  if (isWLLoading)
    return (
      <div className="wrapper-content">
        <Loader />
      </div>
    );

  return (
    <div className="wrapper-content">
      {shouldShowHeaderFooter ? <Header /> : null}
      {isSubdomainWrong ? (
        <Box className={styles.root}>
          <div
            style={{
              color: "#000000",
              textAlign: "center",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <h2 style={{ textAlign: "center", padding: "20px" }}>
              You are in the wrong subdomain or the administrator changed the
              settings
            </h2>
            <div style={{ fontSize: "20px" }}>
              Follow the{" "}
              <b>
                <a
                  href={redirectUrl || "https://portal.esimplifysolutions.us/"}
                >
                  link
                </a>
              </b>{" "}
              to login
            </div>
          </div>
        </Box>
      ) : (
        <Body history={props.history} />
      )}
      {shouldShowHeaderFooter ? <Footer /> : null}
      {true === loader ? <Loader /> : null}
      {null !== notification ? (
        <Notification notification={notification} />
      ) : null}
      {null !== exeption && "login" !== exeption.code ? (
        <ExeptionPopup exeption={exeption} />
      ) : null}
    </div>
  );
};

export default App;
