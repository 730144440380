import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "../../../route/routes";
import LoginVerification from "./LoginVerification";
import SMSSetup from "./SMSSetup";
import TOTPSetup from "./TOTPSetup";
import { getQrUrl } from "../../../actions/actions";
import SelectMFA from "./SelectMFAType";
import SetupMFA from "./SetupMFA";
import EsimplifyLogo from "../../../img/eSimplify_logo.svg";

const MultiFactorAuth = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const mfa = useSelector((state) => state.mfa);
  const [mfaValue, setMfaValue] = useState(false);

  const isMfaSetup = () => {
    if (undefined === user || undefined === user.attributes) {
      return false;
    }
    return user.attributes["phone_number_verified"];
  };

  useEffect(() => {
    if (
      "not authenticated" === user ||
      (undefined === user.preferredMFA && undefined === user.challengeName)
    ) {
      history.push(routes.LOGIN);
    }
    if (
      "SMS_MFA" === user.preferredMFA ||
      "SOFTWARE_TOKEN_MFA" === user.preferredMFA
    ) {
      history.push(routes.MAIN_PAIGE);
    }
    if (
      undefined === mfa.qrUrl &&
      "not authenticated" !== user &&
      "NOMFA" === user.preferredMFA
    ) {
      dispatch(getQrUrl(user, dispatch));
    }
  });

  const setTabTitle = (challengeName) => {
    if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
      return "TOTP multi-factor authentication";
    }

    if (user.challengeName === "SMS_MFA") {
      return "SMS multi-factor authentication";
    }

    return "multi-factor authentication";
  };

  if (
    isMfaSetup() &&
    undefined !== user.challengeName &&
    user.challengeName === "SELECT_MFA_TYPE"
  ) {
    return <div></div>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      padding="32px 0"
    >
      <Grid
        container
        style={{
          width: "400px",
        }}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            style={{ width: "100%" }}
          >
            <img
              src={EsimplifyLogo}
              alt="logo"
              style={{ width: "400px", height: "auto", paddingBottom: 24 }}
            />
            {undefined !== user.challengeName ? (
              <Typography
                variant="h5"
                style={{ paddingBottom: 24, fontWeight: 600 }}
              >
                {setTabTitle(user.challengeName)}
              </Typography>
            ) : (
              <Typography
                variant="h5"
                style={{ paddingBottom: 24, fontWeight: 600 }}
              >
                Setup multi-factor authentication
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {undefined !== user.challengeName &&
          (user.challengeName === "SMS_MFA" ||
            user.challengeName === "SOFTWARE_TOKEN_MFA") ? (
            <LoginVerification />
          ) : null}
          {(undefined !== user.challengeName &&
            user.challengeName === "SELECT_MFA_TYPE") ||
          isMfaSetup() ? (
            <SelectMFA />
          ) : null}
          {undefined !== user.preferredMFA &&
          false === mfaValue &&
          !isMfaSetup() ? (
            <SetupMFA />
          ) : null}
          {undefined !== user.preferredMFA && "SMS" === mfaValue ? (
            <SMSSetup setup={false} setMfaValue={setMfaValue} />
          ) : null}
          {undefined !== user.preferredMFA && "TOTP" === mfaValue ? (
            <TOTPSetup setMfaValue={setMfaValue} />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultiFactorAuth;
