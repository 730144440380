import React from "react";
import Container from "@material-ui/core/Container";
import { contentWrapper } from "../../css/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { routes } from "../../route/routes";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import ImportData from "./pages/ImportData";
import Dashboard from "./pages/Dashboard/Dashboard";
import ThankYou from "./pages/ThankYou";
import NewPasswordForm from "../forms/NewPasswordForm";
import RegisterUserForm from "../forms/RegisterUserForm";
import ForgotPassword from "../forms/ForgotPassword";
import LoginUserForm from "../forms/LoginUserForm";
import EmailConfirmation from "../forms/EmailConfirmation";
import MultiFactorAuth from "../forms/multifactor-auth/MultiFactorAuth";
import { Box } from "@material-ui/core";
import TermsAndConditions from "./pages/TermsAndCondition";

export default (props) => {
  const styles = contentWrapper();
  const user = useSelector((state) => state.user);
  const isMfaSetuped = useSelector((state) => state.isMfaSetup);
  if (user === null) {
    return (
      <Container className={styles.root}>
        <div></div>
      </Container>
    );
  }

  const isMfaSetup = () => {
    if (undefined === user || undefined === user.attributes) {
      return false;
    }
    return (
      user.attributes["phone_number_verified"] ||
      (null !== isMfaSetuped &&
        undefined !== isMfaSetuped.TOTP &&
        undefined !== isMfaSetuped.SMS_MFA)
    );
  };

  if (
    undefined !== user.preferredMFA &&
    "NOMFA" === user.preferredMFA &&
    false === isMfaSetup()
  ) {
    return <MultiFactorAuth />;
  }

  return (
    <Box className={styles.root}>
      <Switch>
        <Route exact path={routes.MAIN_PAIGE}>
          {"not authenticated" === user ||
          (null !== user && false === user.userConfirmed) ? (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                search: props.history.location.search,
              }}
            />
          ) : (
            <Home />
          )}
        </Route>

        {"not authenticated" === user ||
        (null !== user && false === user.userConfirmed) ? (
          <Route
            exact
            path={routes.LOGIN}
            render={() => <LoginUserForm history={props.history} />}
          />
        ) : null}

        {"not authenticated" === user ||
        (null !== user && false === user.userConfirmed) ? (
          <Route
            exact
            path={routes.REGISTER}
            history={props.history}
            component={RegisterUserForm}
          />
        ) : null}

        <Route
          exact
          path={routes.EMAIL_CONFIRMATION}
          render={() => <EmailConfirmation history={props.history} />}
        />

        <Route
          exact
          path={routes.TERMS_AND_CONDITION}
          render={() => <TermsAndConditions history={props.history} />}
        />

        <Route
          exact
          path={routes.FORGOT_PASSWORD}
          history={props.history}
          component={ForgotPassword}
        />
        <Route
          exact
          path={routes.NEW_PASSWORD}
          history={props.history}
          component={NewPasswordForm}
        />
        <Route exact path={routes.DASHBOARD}>
          {"not authenticated" === user ||
          (null !== user && false === user.userConfirmed) ? (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                search: props.history.location.search,
              }}
            />
          ) : (
            <Dashboard />
          )}
        </Route>
        <Route exact path={routes.IMPORT_DATA}>
          {"not authenticated" === user ||
          (null !== user && false === user.userConfirmed) ? (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                search: props.history.location.search,
              }}
            />
          ) : (
            <ImportData />
          )}
        </Route>
        <Route exact path={routes.SETTINGS}>
          {"not authenticated" === user ||
          (null !== user && false === user.userConfirmed) ? (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                search: props.history.location.search,
              }}
            />
          ) : (
            <Settings />
          )}
        </Route>
        {"not authenticated" === user ||
        (user && user.Session === null) ||
        (user && user.Session === undefined) ||
        user === null ? (
          <Route path={routes.THANK_YOU} component={ThankYou} />
        ) : null}
        <Route
          exact
          path={routes.SET_MULTIFACTOR_AUTH}
          component={MultiFactorAuth}
        />
      </Switch>
    </Box>
  );
};
