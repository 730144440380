import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getEmbededDashboardsNames,
  getEmbededDashboardUrl,
  loader_add,
} from "../../../../actions/actions";

const DashboardSelect = (props) => {
  const dispatch = useDispatch();
  const dashboards = useSelector(
    (state) =>
      state.embededDashboardsNames &&
      state.embededDashboardsNames.dashboardsNames
  );
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getEmbededDashboardsNames(dispatch));
    dispatch(loader_add());
  }, [dispatch]);

  useEffect(() => {
    if (value !== "") {
      dispatch(
        getEmbededDashboardUrl(dispatch, { dashboardNameFilter: value }, true)
      );
      dispatch(loader_add());
    }
  }, [value, dispatch]);

  const handleChange = (e) => {
    let name = e.target.value;
    setValue(name);
  };

  if (!dashboards) return <div> </div>;

  return (
    <FormControl
      variant="outlined"
      style={{
        width: "180px",
        margin: "5px 10px",
      }}
      size="small"
    >
      <InputLabel style={{ fontSize: 13 }}>Desired Dashboard</InputLabel>
      <Select value={value} onChange={handleChange} label="Desired Dashboard">
        {dashboards.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DashboardSelect;
