import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  loader_add,
  sendVerificationCodeToPhoneNumber,
  sendPhoneCorfirmationCode,
} from "../../../actions/actions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text1: {
    fontSize: 14,
    color: theme.palette.grey[700],
  },
  label: { fontWeight: 500, fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
  warningText: {
    backgroundColor: "#FEF3F2", // Red with faded opacity
    color: "#B42318", // Red text
    padding: "10px",
    borderRadius: "8px",
    fontSize: "14px",
  },
}));

const SMSSetup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [phoneCode, setPhoneCode] = useState("");
  const { setup } = props;

  const verifyPhone = () => {
    dispatch(sendVerificationCodeToPhoneNumber(dispatch));
  };

  const sendPhone = () => {
    dispatch(
      sendPhoneCorfirmationCode(phoneCode, user, dispatch, history, setup)
    );
    dispatch(loader_add());
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.text1}>
          You can also use your phone number to get verification codes
          verification for your account
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.warningText}>
          <Typography style={{ fontSize: 14 }}>
            Some mobile carriers (for example TMobile and Sprint) block certain
            SMS (messages). If you use one of these carriers, chose the Google
            Authenticator authentication method otherwise you will not be able
            to complete the registration.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>
          {" "}
          Send verification code to your phone: {user.attributes.phone_number}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            type="submit"
            variant="contained"
            onClick={(e) => verifyPhone(e)}
            className={classes.button}
            fullWidth
          >
            Send Code
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.label}>Code</Typography>
        <TextField
          name="Code"
          variant="outlined"
          className={classes.textField}
          fullWidth
          size="small"
          placeholder="Enter code"
          onChange={(e) => setPhoneCode(e.target.value)}
          onKeyUp={(e) => setPhoneCode(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            type="submit"
            variant="contained"
            onClick={(e) => sendPhone(e)}
            className={classes.button}
            fullWidth
          >
            Confirm
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SMSSetup;
