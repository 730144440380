import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import {
  loader_add,
  loginConfirm,
  wlLoader_add,
} from "../../../actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  text1: {
    fontSize: 14,
    color: theme.palette.grey[700],
  },
  label: { fontWeight: 500, fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
}));

const LoginVerification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [code, setCode] = useState("");

  const confirm = (e) => {
    e.preventDefault();
    dispatch(loginConfirm(user, code, history, dispatch));
    dispatch(loader_add());
    dispatch(wlLoader_add());
  };

  return (
    <form noValidate autoComplete="off" onSubmit={confirm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.label}>Code</Typography>
          <TextField
            name="Code"
            variant="outlined"
            className={classes.textField}
            fullWidth
            size="small"
            placeholder="Enter code"
            required
            onChange={(e) => setCode(e.target.value)}
            onKeyUp={(e) => setCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              type="submit"
              variant="contained"
              onClick={confirm}
              className={classes.button}
              fullWidth
            >
              Confirm
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginVerification;
