import React from "react";
import { Container, Typography, Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EsimplifyLogo from "../../img/eSimplify_logo.svg";
import { useDispatch } from "react-redux";
import { clearRecoveryPasswordState } from "../../actions/actions";

const useStyles = makeStyles((theme) => ({
  text1: {
    marginTop: "16px",
    width: "350px",
    color: theme.palette.grey[600],
    fontSize: "0.8rem",
  },

  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
}));

const PasswordSuccess = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const clearState = (e) => {
    dispatch(clearRecoveryPasswordState(dispatch));
  };
  return (
    <>
      <Container className={classes.container}>
        <Box
          sx={{
            p: 4,
            bgcolor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            style={{ width: "100%" }}
          >
            <img
              src={EsimplifyLogo}
              alt="logo"
              style={{ width: "350px", height: "auto", paddingBottom: 24 }}
            />
            <Typography
              variant="body2"
              align="center"
              className={classes.text1}
            >
              Your password has been successfully reset. You can now log in with
              your new password.
            </Typography>
          </Box>
          {/* Submit Button */}
          <Box
            display="flex"
            justifyContent="center"
            mt={2}
            onClick={(e) => clearState(e)}
          >
            <Link
              href="/login"
              align="center"
              style={{
                fontSize: "0.8rem",
                fontWeight: 600,
                color: "#013C44",
              }}
            >
              Back to Sign in
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default PasswordSuccess;
