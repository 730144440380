export const routes = {
  MAIN_PAIGE: "/",
  LOGIN: "/login",
  EMAIL_CONFIRMATION: "/email-confirmation",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  TERMS_AND_CONDITION: "/terms-and-condition",
  NEW_PASSWORD: "/new-password",
  DASHBOARD: "/dashboard",
  IMPORT_DATA: "/import-data",
  ACTION_ITEMS: "/action-items",
  SETTINGS: "/settings",
  THANK_YOU: "/thank-you",
  SET_MULTIFACTOR_AUTH: "/multifactor-auth",
};
