import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Box,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EsimplifyLogo from "../../img/eSimplify_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  passwordReset,
  loader_add,
  clearRecoveryPasswordState,
} from "../../actions/actions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  text1: {
    marginTop: "16px",
    color: theme.palette.grey[600],
    fontSize: "0.8rem",
  },
  label: { fontWeight: 500, fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    width: "350px",
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
}));

const validationSchema = Yup.object({
  code: Yup.string().required("code is required"),
  password: Yup.string()
    .min(10, "Password must be at least 10 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .required("Password is required"),
});

const ChangePassword = ({ username }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const exeption = useSelector((state) => state.exeption);
  const clearState = (e) => {
    dispatch(clearRecoveryPasswordState(dispatch));
  };

  const dispatchPasswordReset = async (values) => {
    setLoading(true);
    try {
      dispatch(loader_add());
      dispatch(
        passwordReset(
          { username, password: values.password, code: values.code },
          dispatch
        )
      );
    } catch (error) {
      console.error("Error signing up:", error);
      setErrorMessage(error.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (exeption) {
      setErrorMessage(exeption.message);
    }
  }, [exeption]);
  return (
    <>
      {errorMessage && (
        <Box
          display="flex"
          justifyContent="center"
          style={{
            position: "absolute",
            top: "80px",
            left: 0,
            right: 0,
          }}
        >
          <Alert
            style={{
              width: 300,
              display: "flex",
            }}
            severity="error"
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </Alert>
        </Box>
      )}

      <Container className={classes.container}>
        <Box
          sx={{
            p: 4,
            bgcolor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            style={{ width: "100%" }}
          >
            <img
              src={EsimplifyLogo}
              alt="logo"
              style={{ width: "350px", height: "auto", paddingBottom: 24 }}
            />
            <Typography
              variant="h5"
              style={{ paddingBottom: 24, fontWeight: 600 }}
            >
              Forgot Password
            </Typography>
          </Box>

          <Formik
            initialValues={{
              code: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={dispatchPasswordReset}
          >
            {({ touched, errors }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  {/* Email & Practice Name */}
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Code</Typography>
                    <Field
                      as={TextField}
                      name="code"
                      variant="outlined"
                      className={classes.textField}
                      fullWidth
                      size="small"
                      placeholder="Enter code"
                      error={touched.code && Boolean(errors.code)}
                      helperText={touched.code && errors.code}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      New Password
                    </Typography>
                    <Field
                      as={TextField}
                      name="password"
                      variant="outlined"
                      type="password"
                      className={classes.textField}
                      fullWidth
                      size="small"
                      placeholder="Enter new password"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        loading={loading}
                        fullWidth
                        style={{ width: "350px" }}
                      >
                        Reset Password
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                <Box
                  display="flex"
                  justifyContent="center"
                  mt={2}
                  onClick={(e) => clearState(e)}
                >
                  <Link
                    href="/login"
                    align="center"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      color: "#013C44",
                    }}
                  >
                    Back to Sign in
                  </Link>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
};

export default ChangePassword;
