import { actionTypes } from "../actions/actionTypes";
import { Auth } from "@aws-amplify/auth";
import {
  exeption_add,
  setUser,
  exeption_close,
  loader_remove,
  getUser,
  setS3FieldsForRequest,
  removeImportDataNotification,
  notification_close,
  popup_close,
  setCompanyUsers,
  setCompanyList,
  removeMfaSetupToLocalStorage,
} from "../actions/actions";
import {
  requestAccountRecoverySuccess,
  accountRecoveryResetPasswordSuccess,
} from "../actions/actions";
import { routes } from "../route/routes";

const INITIAL_STATE = {
  forgotPassword: {
    recoveryRequestSentSuccesfully: false,
    accountRecoveryResetPasswordSuccess: false,
  },
  companyDetails: {
    companyName: "",
    name: "",
    logoUrl: "",
    subdomain: "",
  },
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SIGN_UP_REQUEST:
      Auth.signUp({
        ...action.identity,
        attributes: {
          ...action.attributeList,
        },
      })
        .then((data) => {
          action.dispatch(loader_remove());
          action.dispatch(exeption_close());
          action.dispatch(setUser(data));
          return data;
        })
        .catch((err) => {
          action.dispatch(loader_remove());
          action.dispatch(exeption_add(err));
          return err;
        });

      return state;

    case actionTypes.SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: {
          companyName: action.payload.companyName,
          name: action.payload.name,
          logoUrl: action.payload.logoUrl,
          subdomain: action.payload.subdomain,
        },
      };

    case actionTypes.SIGN_IN_REQUEST:
      Auth.signIn(action.identity)
        .then((user) => {
          action.dispatch(setUser(user));
          action.dispatch(exeption_close());
          action.dispatch(loader_remove());
          if (
            "NOMFA" === user.preferredMFA ||
            user.challengeName === "SMS_MFA" ||
            user.challengeName === "SOFTWARE_TOKEN_MFA" ||
            user.challengeName === "SELECT_MFA_TYPE"
          ) {
            action.history.push(routes.SET_MULTIFACTOR_AUTH);
            return user;
          } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            action.history.push(routes.NEW_PASSWORD);
            return user;
          } else {
            action.history.push(routes.MAIN_PAIGE);
            return user;
          }
        })
        .catch((err) => {
          err.code = "login";
          action.dispatch(exeption_add(err));
          action.dispatch(loader_remove());
          console.log(err);
          return err;
        });

      return state;

    case actionTypes.NEW_PASSWORD_CONFIRM:
      Auth.completeNewPassword(
        action.user,
        action.newPassword,
        action.userAttributes
      )
        .then((reponse) => {
          action.dispatch(getUser(action.dispatch));

          if ("NOMFA" === action.user.preferredMFA) {
            action.history.push(routes.SET_MULTIFACTOR_AUTH);
          } else {
            action.history.push(routes.MAIN_PAIGE);
          }

          action.dispatch(loader_remove());
          return reponse;
        })
        .catch((err) => {
          action.dispatch(exeption_add(err));
          action.dispatch(loader_remove());
          return err;
        });

      return state;

    case actionTypes.ACCOUNT_RECOVERY_REQUEST:
      Auth.forgotPassword(action.username)
        .then((reponse) => {
          action.dispatch(requestAccountRecoverySuccess());
          action.dispatch(loader_remove());
          return reponse;
        })
        .catch((err) => {
          action.dispatch(exeption_add(err));
          action.dispatch(loader_remove());
          return err;
        });

      return state;

    case actionTypes.ACCOUNT_RECOVERY_REQUEST_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          recoveryRequestSentSuccesfully: true,
        },
      };

    case actionTypes.ACCOUNT_RECOVERY_PASSWORD_RESET:
      Auth.forgotPasswordSubmit(action.username, action.code, action.password)
        .then((reponse) => {
          action.dispatch(loader_remove());
          action.dispatch(accountRecoveryResetPasswordSuccess());
          return reponse;
        })
        .catch((err) => {
          action.dispatch(loader_remove());
          action.dispatch(exeption_add(err));
          return err;
        });

      return state;

    case actionTypes.ACCOUNT_RECOVERY_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          recoveryRequestSentSuccesfully: false,
          accountRecoveryResetPasswordSuccess: true,
        },
      };

    case actionTypes.RECOVERY_PASSWORD_CLEAR_STATE:
      return {
        forgotPassword: {
          recoveryRequestSentSuccesfully: false,
          accountRecoveryResetPasswordSuccess: false,
        },
      };

    case actionTypes.SIGN_OUT:
      Auth.signOut()
        .then((user) => {
          action.dispatch(getUser(action.dispatch));
          action.dispatch(loader_remove());
          action.dispatch(setS3FieldsForRequest(null, action.dispatch));
          action.dispatch(removeImportDataNotification());
          action.dispatch(notification_close());
          action.dispatch(popup_close());
          action.dispatch(setCompanyUsers(null, action.dispatch));
          action.dispatch(setCompanyList(null, action.dispatch));
          action.dispatch(removeMfaSetupToLocalStorage());
          action.history.push(routes.LOGIN);
          return user;
        })
        .catch((err) => {
          action.dispatch(setS3FieldsForRequest(null, action.dispatch));
          action.dispatch(removeImportDataNotification());
          action.dispatch(notification_close());
          action.dispatch(loader_remove());
          action.dispatch(exeption_add(err));
          return err;
        });
      return state;

    default:
      break;
  }

  return state;
}
