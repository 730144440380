import React from "react";
import { Container, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EsimplifyLogo from "../../img/eSimplify_logo.svg";

const useStyles = makeStyles((theme) => ({
  text1: {
    marginTop: "16px",
    width: "350px",
    color: theme.palette.grey[600],
    fontSize: "0.8rem",
  },

  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
}));

const EmailConfirmation = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box
        sx={{
          p: 4,
          bgcolor: "white",
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          style={{ width: "100%" }}
        >
          <img
            src={EsimplifyLogo}
            alt="logo"
            style={{ width: "350px", height: "auto", paddingBottom: 24 }}
          />
          <Typography variant="body2" align="center" className={classes.text1}>
            Please look in your email inbox for a confirmation and respond to
            the verification email. If you do not see the email, please look to
            see if the email was directed to a junk email box.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default EmailConfirmation;
