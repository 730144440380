import React from "react";
import { useDispatch } from "react-redux";
import { exeption_close } from "../../actions/actions";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const ExeptionPopup = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        style={{
          position: "absolute",
          top: "80px",
          left: 0,
          right: 0,
        }}
      >
        <Alert
          style={{
            width: 300,
            display: "flex",
          }}
          severity="error"
          onClose={() => dispatch(exeption_close())}
        >
          {props.exeption.message}
        </Alert>
      </Box>
    </>
  );
};
export default ExeptionPopup;
