import React, { useEffect, useState } from "react";
import TOTPSetup from "./TOTPSetup";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  resetMfaSetupStatus,
  setPreferedMfaSms,
  setPreferedMfaTotp,
  userMfaWasSet,
} from "../../../actions/actions";
import { useHistory } from "react-router-dom";
import { routes } from "../../../route/routes";
import SMSSetup from "./SMSSetup";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text1: {
    fontSize: 14,
    color: theme.palette.grey[700],
  },
  label: { fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
}));
const SetupMFA = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const mfaSetupStatus = useSelector((state) => state.mfa.setupStatus);
  const [tab, setTab] = useState(0);

  const handleTabChange = (_, value) => {
    setTab(value);
  };

  useEffect(() => {
    if (mfaSetupStatus.totpConfigured && mfaSetupStatus.smsConfigured) {
      // console.log('before userMfaWasSet(dispatch, true)');
      dispatch(userMfaWasSet(dispatch, true));
      // console.log('before getUser(dispatch)');
      // dispatch(getUser(dispatch));
      // console.log('before history.push(routes.MAIN_PAIGE);');
      history.push(routes.MAIN_PAIGE);
      dispatch(resetMfaSetupStatus());
    }
  }, [mfaSetupStatus, dispatch, history]);

  const submitMfaSetup = () => {
    if (!(mfaSetupStatus.totpConfigured || mfaSetupStatus.smsConfigured))
      return;

    if (mfaSetupStatus.totpConfigured && !mfaSetupStatus.smsConfigured) {
      dispatch(setPreferedMfaTotp(user, dispatch));
      dispatch(userMfaWasSet(dispatch, false));
    }

    if (mfaSetupStatus.smsConfigured && !mfaSetupStatus.totpConfigured) {
      dispatch(setPreferedMfaSms(user, dispatch));
      dispatch(userMfaWasSet(dispatch, false));
    }

    if (mfaSetupStatus.smsConfigured && mfaSetupStatus.totpConfigured) {
      dispatch(userMfaWasSet(dispatch, true));
    }

    history.push(routes.MAIN_PAIGE);
  };

  return (
    <>
      <Typography className={classes.label}>
        For safety reasons we require multi-factor authentication on each sign
        in. You need to set up at least one type of MFA : TOTP or SMS.
        <br />
        You can set up both of them, if you wish. In this case, you will be able
        to choose preferred type on each sign in.
      </Typography>

      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        TabIndicatorProps={{
          style: { backgroundColor: "#013C44" },
        }}
        style={{
          fontSize: 14,
          paddingBottom: 10,
        }}
      >
        <Tab
          label="Setup TOTP MFA"
          style={{
            color: tab === 0 ? "#013C44" : "#717680",
            textTransform: "none",
          }}
        />
        <Tab
          label="Setup SMS MFA"
          style={{
            color: tab === 1 ? "#013C44" : "#717680",
            textTransform: "none",
          }}
        />
      </Tabs>
      <TabPanel value={tab} index={0}>
        {mfaSetupStatus.totpConfigured && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.text1}>
                TOTP code was successfully confirmed. You can either finish by
                clicking button bellow or setup SMS MFA.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => submitMfaSetup()}
                  className={classes.button}
                  fullWidth
                >
                  Finish
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
        {!mfaSetupStatus.totpConfigured && <TOTPSetup />}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {mfaSetupStatus.smsConfigured && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.text1}>
                SMS code was successfully confirmed. You can either finish by
                clicking button bellow or setup TOTP MFA.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => submitMfaSetup()}
                  className={classes.button}
                  fullWidth
                >
                  Finish
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
        {!mfaSetupStatus.smsConfigured && <SMSSetup setup={true} />}
      </TabPanel>
    </>
  );
};

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default SetupMFA;
