import React from "react";
import { Box, Container, Typography, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Footer = () => {
  const user = useSelector((state) => state.user);
  const isMfaSetuped = useSelector((state) => state.isMfaSetup);

  const footerTextColor = useSelector(
    (state) => state.colors?.colors?.footerFontColor
  );
  const footerBgColor = useSelector(
    (state) => state.colors?.colors?.footerColor
  );
  const isMfaSetup = () => {
    if (undefined === user || undefined === user.attributes) {
      return false;
    }

    return (
      user.attributes["phone_number_verified"] ||
      (null !== isMfaSetuped &&
        undefined !== isMfaSetuped.TOTP &&
        undefined !== isMfaSetuped.SMS_MFA)
    );
  };

  if (
    user?.preferredMFA !== undefined &&
    user.preferredMFA === "NOMFA" &&
    isMfaSetup() === false
  ) {
    return <div></div>;
  }

  return (
    <Box bgcolor={footerBgColor || "#fff"} py={4} px={4}>
      <Divider style={{ width: "100%", marginBottom: "30px" }} />
      <Container
        disableGutters={true}
        maxWidth={false}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          style={{
            color: footerTextColor || "#717680",
          }}
        >
          © 2025 eSimplify. All rights reserved.
        </Typography>
        <Link
          to="/terms-and-condition"
          style={{
            textDecoration: "none",
            fontWeight: 500,
            color: footerTextColor || "#717680",
            cursor: "pointer",
          }}
        >
          Terms & Conditions
        </Link>
      </Container>
    </Box>
  );
};

export default Footer;
