import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";
import { getQrUrl, loader_add, totpVerify } from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text1: {
    fontSize: 14,
    color: theme.palette.grey[700],
  },
  text2: {
    fontSize: 15,
    color: theme.palette.grey[700],
  },
  label: { fontWeight: 500, fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
  qrGrid: {
    backgroundColor: "#f5f5f5", // Grey background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "8px",
  },
}));

const TOTPSetup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const mfa = useSelector((state) => state.mfa);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (
      undefined === mfa.qrUrl &&
      "not authenticated" !== user &&
      "NOMFA" === user.preferredMFA
    ) {
      dispatch(getQrUrl(user, dispatch));
    }
    //eslint-disable-next-line
  }, []);

  const getQRCode = () => {
    if (undefined !== mfa.qrUrl) {
      return <QRCode value={mfa.qrUrl} />;
    }
    return "";
  };

  const confirm = () => {
    dispatch(totpVerify(user, code, dispatch, history));
    dispatch(loader_add());
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.text1}>
          With TOTP 2-Step Verification, signing into your account will require
          both your password and a verification code you can generate with
          Google Authenticator app. Once configured, you can get verification
          codes without the need for a network or cellular connection.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontSize: 16, fontWeight: 500 }}>
          Download Google Authenticator:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.text2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          >
            Google play: Google Authenticator
          </a>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.text2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/app/google-authenticator/id388497605"
          >
            App Store: Google Authenticator
          </a>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.qrGrid}>{getQRCode()}</Box>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Code</Typography>
        <TextField
          name="Code"
          variant="outlined"
          className={classes.textField}
          fullWidth
          size="small"
          placeholder="Enter code"
          onChange={(e) => setCode(e.target.value)}
          onKeyUp={(e) => setCode(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            type="submit"
            variant="contained"
            onClick={(e) => confirm()}
            className={classes.button}
            fullWidth
          >
            Confirm
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TOTPSetup;
