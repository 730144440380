import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { headerLogoStyles } from "../../css/styles";
import Container from "@material-ui/core/Container";
import logo from "../../img/esimplify.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  signOut,
  loader_add,
  setColor,
  updateWhiteLabelColor,
  setLogoutStatus,
  wlLoader_remove,
} from "../../actions/actions";
import NavBarHeader from "./NavBarHeader";
import MobileHeader from "./MobileHeader";
import ChangeBgColorBtn from "./ChangeBgColorBtn";
import AccountPopover from "./AccountPopover";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default () => {
  const classes = headerLogoStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isMfaSetuped = useSelector((state) => state.isMfaSetup);
  const companyAccess = useSelector((state) => state.companyAccess);
  const whiteLabel = useSelector((state) => state.whiteLabel);
  const isWLLoading = useSelector((state) => state.wlLoader);
  const isSubdomainWrong = useSelector((state) => state.isSubdomainWrong);

  const headerColor = useSelector(
    (state) => state.colors?.colors?.headerColor || "#013C44"
  );
  const footerColor = useSelector(
    (state) => state.colors?.colors?.footerColor || "#383939"
  );
  const headerFontColor = useSelector(
    (state) => state.colors?.colors?.headerFontColor || "#ffffff"
  );
  const footerFontColor = useSelector(
    (state) => state.colors?.colors?.footerFontColor || "#d3d3d3"
  );
  const whiteLabelId = whiteLabel?.id;
  const whiteLabelLogo = whiteLabel?.logoUrl;

  let history = useHistory();
  const [mobile, setMobile] = useState(false);
  const [wlLogo, setWlLogo] = useState(whiteLabelLogo);

  useEffect(() => {
    if (426 >= window.innerWidth) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    setWlLogo(whiteLabelLogo);
  }, [whiteLabelLogo]);

  const logout = (e) => {
    dispatch(loader_add());
    dispatch(signOut(dispatch, history));
    if (companyAccess === "whiteLabel") {
      dispatch(setLogoutStatus(true));
    }
    dispatch(wlLoader_remove());
  };

  const handleChangeComplete = (color) => {
    dispatch(
      setColor({
        headerColor: color.hex,
        footerColor,
        footerFontColor,
        headerFontColor,
      })
    );
  };

  const handleChangeCompleteFooter = (color) => {
    dispatch(
      setColor({
        footerColor: color.hex,
        headerColor,
        footerFontColor,
        headerFontColor,
      })
    );
  };

  const handleChangeFooterFontColor = (color) => {
    dispatch(
      setColor({
        footerFontColor: color.hex,
        headerColor,
        footerColor,
        headerFontColor,
      })
    );
  };

  const handleChangeHeaderFontColor = (color) => {
    dispatch(
      setColor({
        headerFontColor: color.hex,
        headerColor,
        footerColor,
        footerFontColor,
      })
    );
  };

  const handleSaveChanges = () => {
    if (whiteLabelId && !isWLLoading) {
      dispatch(
        updateWhiteLabelColor(
          whiteLabelId,
          { headerFontColor, headerColor, footerColor, footerFontColor },
          dispatch
        )
      );
    }
  };

  if (null === user || isWLLoading) {
    return <div></div>;
  }

  const isMfaSetup = () => {
    if (undefined === user || undefined === user.attributes) {
      return false;
    }

    return (
      user.attributes["phone_number_verified"] ||
      (null !== isMfaSetuped &&
        undefined !== isMfaSetuped.TOTP &&
        undefined !== isMfaSetuped.SMS_MFA)
    );
  };

  if (isSubdomainWrong) {
    return (
      <Box
        width="100%"
        bgcolor={
          companyAccess === "whiteLabel" && headerColor
            ? headerColor
            : "#013C44"
        }
        minHeight="60px"
      >
        <Container style={{ width: "95%" }}>
          {companyAccess !== "whiteLabel" ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {isWLLoading
                ? null
                : companyAccess !== "whiteLabel" && (
                    <div className={classes.root}>
                      <img className={classes.logo} src={logo} alt="logo" />{" "}
                    </div>
                  )}
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {isWLLoading
                ? null
                : companyAccess === "whiteLabel" && (
                    <div className={classes.root}>
                      <img className={classes.logo} src={wlLogo} alt="logo" />{" "}
                    </div>
                  )}
            </Box>
          )}
        </Container>
      </Box>
    );
  }

  if (null !== user || isSubdomainWrong) {
    if (
      false === user.userConfirmed ||
      "not authenticated" === user ||
      undefined === user.attributes
    ) {
      return (
        <Box
          width="100%"
          bgcolor={
            companyAccess === "whiteLabel" && headerColor
              ? headerColor
              : "#013C44"
          }
          minHeight="60px"
        >
          <Container style={{ width: "95%" }}>
            {companyAccess !== "whiteLabel" ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {isWLLoading
                  ? null
                  : companyAccess !== "whiteLabel" && (
                      <div className={classes.root}>
                        <img className={classes.logo} src={logo} alt="logo" />{" "}
                      </div>
                    )}
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {isWLLoading
                  ? null
                  : companyAccess === "whiteLabel" && (
                      <div className={classes.root}>
                        <img className={classes.logo} src={wlLogo} alt="logo" />{" "}
                      </div>
                    )}
              </Box>
            )}
          </Container>
        </Box>
      );
    }
  }

  if (
    undefined !== user.preferredMFA &&
    "NOMFA" === user.preferredMFA &&
    false === isMfaSetup()
  ) {
    return <div></div>;
  }

  if (true === mobile && "not authenticated" !== user) {
    return <MobileHeader mobile={mobile} />;
  }

  return (
    <Box
      width="100%"
      bgcolor={
        whiteLabel && companyAccess === "whiteLabel" && headerColor
          ? headerColor
          : "#013C44"
      }
      minHeight="60px"
      marginBottom="12px"
    >
      <Container maxWidth={"xl"}>
        {isWLLoading ? null : (
          <Box
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent:
                companyAccess !== "whiteLabel" ? "space-between" : "flex-start",
            }}
          >
            <Box
              style={{
                width: companyAccess !== "whiteLabel" ? "auto" : "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {isWLLoading
                ? null
                : companyAccess !== "whiteLabel" && (
                    <div className={classes.root}>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <img className={classes.logo} src={logo} alt="logo" />{" "}
                      </Link>
                    </div>
                  )}
              {"not authenticated" !== user ? (
                isWLLoading ? null : (
                  <NavBarHeader mobile={mobile} />
                )
              ) : null}
            </Box>
            <Box
              style={{
                width: companyAccess !== "whiteLabel" ? "auto" : "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <ChangeBgColorBtn
                user={user}
                handleChangeComplete={handleChangeComplete}
                handleChangeCompleteFooter={handleChangeCompleteFooter}
                headerColor={headerColor}
                footerColor={footerColor}
                handleChangeHeaderFontColor={handleChangeHeaderFontColor}
                handleChangeFooterFontColor={handleChangeFooterFontColor}
                headerFontColor={headerFontColor}
                footerFontColor={footerFontColor}
                handleSaveChanges={handleSaveChanges}
              />
              {"not authenticated" !== user ? (
                isWLLoading ? null : (
                  <Box className={classes.logout}>
                    <AccountPopover
                      logout={logout}
                      user={user}
                      companyAccess={companyAccess}
                      headerFontColor={headerFontColor}
                    />
                  </Box>
                )
              ) : null}
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};
