import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  exeption_add,
  exeption_close,
  getEmbededDashboardUrl,
  loader_add,
  notification_close,
} from "../../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import EmbededDashboard from "./EmbededDashboard";
import DashboardSelect from "./DashboardSelect";

const Dashboard = () => {
  let dashboard;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const onDashboardLoad = (payload) => {
    dispatch(notification_close());
    dispatch(exeption_close());
  };

  const onError = (payload) => {
    dispatch(exeption_add({ name: "err" }));
  };

  const embedDashboardInit = (url) => {
    const containerDiv = document.getElementById("dashbordtest");
    containerDiv.innerHTML = "";
    const options = {
      url: url,
      container: containerDiv,
      parameters: {
        country: "United States",
        states: "[ALL]",
      },
      scrolling: "no",
      height: "AutoFit",
      loadingHeight: "450px",
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
      defaultEmbeddingVisualType: "TABLE",
      printEnabled: true,
    };
    dashboard = embedDashboard(options);
    dashboard.on("error", onError);
    dashboard.on("load", onDashboardLoad);
  };

  const userName = (attributes) => {
    let userName = "";
    let lastName = "";
    if (!!attributes["custom:lastName"]) {
      lastName = attributes["custom:lastName"];
    }
    if (!!attributes["custom:firstName"]) {
      userName = attributes["custom:firstName"];
    }

    return `${userName} ${lastName}`.trim();
  };

  useEffect(() => {
    dispatch(getEmbededDashboardUrl(dispatch));
    dispatch(loader_add());
  }, [dispatch]);

  return (
    <Box style={{ color: "#000000" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#ffffff"
        height="80px"
        paddingX={2}
        boxShadow={1} // Adds a slight shadow
      >
        <Typography style={{ fontWeight: 600, fontSize: 24 }}>
          {"not authenticated" !== user ? (
            <div
              style={{
                padding: "10px",
              }}
            >
              Welcome back, {userName(user.attributes)}
            </div>
          ) : null}
        </Typography>
        <DashboardSelect />
      </Box>
      <EmbededDashboard embedDashboardInit={embedDashboardInit} />
    </Box>
  );
};

export default Dashboard;
