import React from "react";
import { Container, Typography, Box } from "@material-ui/core";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Box style={{ maxWidth: 760, margin: "0 auto", padding: "60px 0" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Terms & Conditions
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Please read these terms and conditions carefully before using our
          services. By accessing or using the platform, you agree to be bound by
          these terms.
        </Typography>
      </Box>
      <Box
        style={{
          maxWidth: 720,
          margin: "0 auto",
          paddingBottom: "60px",
        }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            Welcome to Our Terms and Conditions
          </Typography>
          <Typography variant="body1" paragraph>
            We're glad you're here! These terms outline the rules and
            regulations for using our platform. Let’s make sure we’re on the
            same page.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Our Commitment to Transparency
          </Typography>
          <Typography variant="body1" paragraph>
            We value transparency and fairness. These terms explain your rights
            and responsibilities when using our services.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Know Your Rights and Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            Understanding these terms will help you use our platform responsibly
            and make the most of your experience.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Legal Agreement Between You and Us
          </Typography>
          <Typography variant="body1" paragraph>
            This document serves as a legally binding agreement. By using our
            platform, you accept and agree to these terms.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Understanding Our Terms
          </Typography>
          <Typography variant="body1" paragraph>
            These terms help you understand how our services work and what you
            can expect from us.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Your Guide to Using Our Services
          </Typography>
          <Typography variant="body1" paragraph>
            We’re here to make things simple. This guide explains your rights
            and obligations when using our platform.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            No Legal Jargon, Just Clear Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We’ve kept our terms simple and transparent so you can understand
            them without legal expertise.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
