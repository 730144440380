import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Divider,
  Link,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import EsimplifyLogo from "../../img/eSimplify_logo.svg";
import { signUpKeys } from "./helper/attributesKey";
import { helper } from "./helper/helper";
import { loader_add, register } from "../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "../../route/routes";
import { getUser } from "../../actions/actions";

const useStyles = makeStyles((theme) => ({
  text1: {
    marginTop: "16px",
    color: theme.palette.grey[600],
    fontSize: "0.8rem",
  },
  text2: {
    marginLeft: "4px",
    textDecoration: "none",
    fontWeight: 600,
    color: "#013C44",
  },

  label: { fontWeight: 500, fontSize: 14, color: theme.palette.grey[700] },
  textField: {
    width: "100%",
    "& input[type=number]": {
      "-moz-appearance": "textfield", // Firefox
    },
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        "-webkit-appearance": "none", // Chrome, Safari, Edge
        margin: 0,
      },
    "& .MuiInputBase-root": {
      borderRadius: "8px",

      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Same border color for hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#888 !important", // Ensures focus border matches hover color
      },
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    width: 800,
    paddingTop: 48,
    paddingBottom: 48,
  },
  button: {
    backgroundColor: "#013C44",
    color: "#fff",
    "&:hover": { backgroundColor: "#002b25" },
    borderRadius: "8px",
    textTransform: "none",
  },
}));

const validationSchema = Yup.object({
  [signUpKeys.LAST_NAME.name]: Yup.string().required("Last name is required"),
  [signUpKeys.EMAIL.name]: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  [signUpKeys.MOBILE_NUMBER.name]: Yup.string()
    .matches(/^\+?\d{10,15}$/, "Invalid phone number")
    .required("Mobile number is required"),
  [signUpKeys.PASSWORD.name]: Yup.string()
    .min(10, "Password must be at least 10 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .required("Password is required"),
  [signUpKeys.CONFIRM_PSW.name]: Yup.string()
    .oneOf([Yup.ref(signUpKeys.PASSWORD.name)], "Password does not match")
    .required("Confirm password is Required"),
  [signUpKeys.NPI.name]: Yup.string().min(10, "This value is not a valid NPI"),
  [signUpKeys.GROUP_ID.name]: Yup.string().min(
    10,
    "This value is not a valid group ID"
  ),
  [signUpKeys.PRACTICE_NAME.name]: Yup.string(),
  [signUpKeys.STREET_ADDRESS.name]: Yup.string(),
  [signUpKeys.CITY.name]: Yup.string(),
  [signUpKeys.STATE_REGION.name]: Yup.string(),
  [signUpKeys.POSTAL_CODE.name]: Yup.string(),
  termsAccepted: Yup.boolean(),
  recaptcha: Yup.string(),
});

const RegisterUserForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleRegister = async (values) => {
    setLoading(true);
    try {
      const { recaptcha, termsAccepted, confirm, ...filteredData } = values;

      [
        signUpKeys.NPI.name,
        signUpKeys.GROUP_ID.name,
        signUpKeys.POSTAL_CODE.name,
      ].forEach((key) => {
        if (filteredData[key] !== undefined && filteredData[key] !== null) {
          filteredData[key] = filteredData[key].toString();
        }
      });
      filteredData[signUpKeys.DESIRED_ROLE.name] = "";

      const identity = helper.getIdentity(filteredData, signUpKeys);
      const attributeList = helper.getAttributesList(filteredData, signUpKeys);

      dispatch(loader_add());
      dispatch(register(identity, attributeList, dispatch));
    } catch (error) {
      console.error("Error signing up:", error);
    } finally {
      setLoading(false);
    }
  };

  if (null !== user) {
    if (false === user.userConfirmed) {
      history.push(routes.EMAIL_CONFIRMATION);
    }
  }

  useEffect(() => {
    if (null === user) {
      dispatch(getUser(dispatch));
    }
  }, [user, dispatch]);

  return (
    <>
      <Container className={classes.container}>
        <Box
          sx={{
            p: 4,
            bgcolor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            style={{ width: "100%" }}
          >
            <img
              src={EsimplifyLogo}
              alt="logo"
              style={{ width: "300px", height: "auto", paddingBottom: 24 }}
            />
            <Typography
              variant="h5"
              style={{ paddingBottom: 24, fontWeight: 600 }}
            >
              Create an account
            </Typography>
          </Box>

          <Formik
            initialValues={{
              [signUpKeys.FIRST_NAME.name]: "",
              [signUpKeys.LAST_NAME.name]: "",
              [signUpKeys.EMAIL.name]: "",
              [signUpKeys.MOBILE_NUMBER.name]: "",
              [signUpKeys.PASSWORD.name]: "",
              [signUpKeys.CONFIRM_PSW.name]: "",
              [signUpKeys.NPI.name]: "",
              [signUpKeys.GROUP_ID.name]: "",
              [signUpKeys.PRACTICE_NAME.name]: "",
              [signUpKeys.STREET_ADDRESS.name]: "",
              [signUpKeys.CITY.name]: "",
              [signUpKeys.STATE_REGION.name]: "",
              [signUpKeys.POSTAL_CODE.name]: "",
              termsAccepted: false,
              recaptcha: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            {({ setFieldValue, touched, errors, values }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    {/* First Name & Last Name */}
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        First Name
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.FIRST_NAME.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter first name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>NPI</Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.NPI.name}
                        variant="outlined"
                        type="number"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter NPI"
                        error={
                          touched[signUpKeys.NPI.name] &&
                          Boolean(errors[signUpKeys.NPI.name])
                        }
                        helperText={
                          touched[signUpKeys.NPI.name] &&
                          errors[signUpKeys.NPI.name]
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Last Name*
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.LAST_NAME.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter last name"
                        error={
                          touched[signUpKeys.LAST_NAME.name] &&
                          Boolean(errors[signUpKeys.LAST_NAME.name])
                        }
                        helperText={
                          touched[signUpKeys.LAST_NAME.name] &&
                          errors[signUpKeys.LAST_NAME.name]
                        }
                      />
                    </Grid>

                    {/* NPI & Group ID */}

                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Group ID
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.GROUP_ID.name}
                        type="number"
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter group ID"
                        error={
                          touched[signUpKeys.GROUP_ID.name] &&
                          Boolean(errors[signUpKeys.GROUP_ID.name])
                        }
                        helperText={
                          touched[signUpKeys.GROUP_ID.name] &&
                          errors[signUpKeys.GROUP_ID.name]
                        }
                      />
                    </Grid>

                    {/* Email & Practice Name */}
                    <Grid item xs={6}>
                      <Typography className={classes.label}>Email*</Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.EMAIL.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter email"
                        error={
                          touched[signUpKeys.EMAIL.name] &&
                          Boolean(errors[signUpKeys.EMAIL.name])
                        }
                        helperText={
                          touched[signUpKeys.EMAIL.name] &&
                          errors[signUpKeys.EMAIL.name]
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Practice Name
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.PRACTICE_NAME.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter practice name"
                      />
                    </Grid>

                    {/* Mobile Number & Street Address */}
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Mobile Number*
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.MOBILE_NUMBER.name}
                        variant="outlined"
                        type="text"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter mobile number"
                        error={
                          touched[signUpKeys.MOBILE_NUMBER.name] &&
                          Boolean(errors[signUpKeys.MOBILE_NUMBER.name])
                        }
                        helperText={
                          touched[signUpKeys.MOBILE_NUMBER.name] &&
                          errors[signUpKeys.MOBILE_NUMBER.name]
                        }
                        inputProps={{
                          maxLength: 14,
                        }}
                        onChange={(e) => {
                          const { value } = e.target;
                          const numericValue = value.replace(/\D/g, "");
                          if (numericValue.length <= 14) {
                            setFieldValue(
                              signUpKeys.MOBILE_NUMBER.name,
                              numericValue
                            );
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">+1</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Street Address
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.STREET_ADDRESS.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter street address"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Password*
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.PASSWORD.name}
                        type="password"
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter password"
                        error={
                          touched[signUpKeys.PASSWORD.name] &&
                          Boolean(errors[signUpKeys.PASSWORD.name])
                        }
                        helperText={
                          touched[signUpKeys.PASSWORD.name] &&
                          errors[signUpKeys.PASSWORD.name]
                        }
                      />
                    </Grid>

                    {/* City & State */}
                    <Grid item xs={6}>
                      <Typography className={classes.label}>City</Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.CITY.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter city"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>
                        Confirm Password*
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.CONFIRM_PSW.name}
                        type="password"
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Re-enter password"
                        error={
                          touched[signUpKeys.CONFIRM_PSW.name] &&
                          Boolean(errors[signUpKeys.CONFIRM_PSW.name])
                        }
                        helperText={
                          touched[signUpKeys.CONFIRM_PSW.name] &&
                          errors[signUpKeys.CONFIRM_PSW.name]
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.label}>
                        State / Region
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.STATE_REGION.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter state"
                      />
                    </Grid>

                    {/* Zip Code */}
                    <Grid item xs={3}>
                      <Typography className={classes.label}>
                        Zip Code
                      </Typography>
                      <Field
                        as={TextField}
                        name={signUpKeys.POSTAL_CODE.name}
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        size="small"
                        placeholder="Enter zip code"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{ margin: "20px 0" }} />
                  </Grid>
                  {/* reCAPTCHA */}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="80px"
                    >
                      <ReCAPTCHA
                        sitekey="6LcWiPsqAAAAAIkBYZ_SqNvrXJJDKvoYjeyBD1vJ"
                        onChange={(value) => setFieldValue("recaptcha", value)}
                      />
                    </Box>
                    <ErrorMessage
                      name="recaptcha"
                      component="div"
                      style={{ color: "red", textAlign: "center" }}
                    />
                  </Grid>

                  {/* Terms & Conditions */}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            name="termsAccepted"
                            style={{ color: "#013C44" }}
                            color="primary"
                            size="small"
                            type="checkbox"
                          />
                        }
                        label={
                          <span className={classes.text1}>
                            I read and agree to
                            <Link
                              href="/terms-and-condition"
                              className={classes.text2}
                            >
                              terms & conditions
                            </Link>
                          </span>
                        }
                        style={{ paddingBottom: 12 }}
                      />
                    </Box>
                    <ErrorMessage
                      name="termsAccepted"
                      component="div"
                      style={{ color: "red", textAlign: "center" }}
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        fullWidth
                        loading={loading}
                        disabled={
                          !values.termsAccepted ||
                          !values.recaptcha ||
                          values.recaptcha.length === 0
                        }
                        style={{ maxWidth: "300px" }}
                      >
                        Create
                      </Button>
                    </Box>
                  </Grid>
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.text1}
                  >
                    Already have an account?
                    <Link href="/login" className={classes.text2}>
                      Log in
                    </Link>
                  </Typography>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Container>
    </>
  );
};

export default RegisterUserForm;
