import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { signUpKeys, keys } from "../helper/attributesKey";
import { helper } from "../helper/helper";
import { validation } from "../helper/validationHelper";
import { loader_add, register, addNewUser } from "../../../actions/actions";
import {
  getEditUserKeys,
  getRole,
} from "../../body/pages/settings/tableComponents/helper";
import { getCognitoUserRole } from "../../../helper/userHelper";

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader);
  const user = useSelector((state) => state.user);
  const popup = useSelector((state) => state.popup);
  const [formState, setFormState] = useState(
    helper.getSignUpSketch(signUpKeys)
  );
  const [validationPsw, setvalidationPsw] = useState([]);
  const [validationEmail, setValidationEmail] = useState(null);
  const [validationNPI, setValidationNPI] = useState(null);
  const [validGroupId, setValidGroupId] = useState(null);
  const [validLastName, setValidLastName] = useState(null);
  const [validPhone, setValidPhone] = useState(null);
  const [validRole, setValidRole] = useState(null);
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [confirmValue, setConfirmValue] = useState("");
  const [msg, setMsg] = useState("");
  const companyBranches = props.companyBranches || null;

  const fillForm = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === signUpKeys.MOBILE_NUMBER.name) {
      value = helper.prettyPhone(value);
    }

    if (validation.isValid(name, value)) {
      return;
    }

    if (name === signUpKeys.PASSWORD.name && 0 < value.length) {
      validation.checkPassword(value, setvalidationPsw);
    }

    if (!!submitAttempt) {
      if (name === signUpKeys.LAST_NAME.name) {
        validation.checkLastName(
          formState[signUpKeys.LAST_NAME.name],
          setValidLastName
        );
      }

      if (name === signUpKeys.GROUP_ID.name) {
        validation.checkGroupId(
          formState[signUpKeys.GROUP_ID.name],
          setValidGroupId
        );
      }

      if (name === signUpKeys.EMAIL.name) {
        validation.checkEmail(
          formState[signUpKeys.EMAIL.name],
          setValidationEmail
        );
      }

      if (name === signUpKeys.NPI.name) {
        validation.checkNPI(formState[signUpKeys.NPI.name], setValidationNPI);
      }

      if (name === signUpKeys.MOBILE_NUMBER.name) {
        validation.checkPhone(
          formState[signUpKeys.MOBILE_NUMBER.name],
          setValidPhone
        );
      }
      if (
        "addUser" === props.type &&
        null !== popup &&
        name === signUpKeys.DESIRED_ROLE.name
      ) {
        validation.checkRole(
          formState[signUpKeys.DESIRED_ROLE.name],
          formState.companyId,
          setValidRole
        );
      }
      setMsg("");
    }

    let newState = { ...formState };
    newState[name] = value;

    setFormState(newState);
  };

  const signUp = () => {
    setSubmitAttempt(true);

    const email = validation.checkEmail(
      formState[signUpKeys.EMAIL.name],
      setValidationEmail
    );
    const npi = validation.checkNPI(
      formState[signUpKeys.NPI.name],
      setValidationNPI
    );
    const lastName = validation.checkLastName(
      formState[signUpKeys.LAST_NAME.name],
      setValidLastName
    );
    const groupId = validation.checkGroupId(
      formState[signUpKeys.GROUP_ID.name],
      setValidGroupId
    );
    const mobile = validation.checkPhone(
      formState[signUpKeys.MOBILE_NUMBER.name],
      setValidPhone
    );

    if (
      "addUser" === props.type &&
      null !== popup &&
      !validation.checkRole(
        formState[signUpKeys.DESIRED_ROLE.name],
        formState.companyId,
        setValidRole
      )
    ) {
      const role = validation.checkRole(
        formState[signUpKeys.DESIRED_ROLE.name],
        formState.companyId,
        setValidRole
      );

      if (!role) {
        setMsg("Please fill in the fields above correctly.");
      }
    }

    if (!email || !npi || !lastName || !groupId || !mobile) {
      setMsg("Please fill in the fields above correctly.");
    }
    if ("" === formState[signUpKeys.PASSWORD.name]) {
      return setvalidationPsw([
        "Password should contain at least 10 characters",
      ]);
    }
    if (
      !email ||
      !npi ||
      !lastName ||
      !groupId ||
      !mobile ||
      validation.checkConfirm(
        formState[signUpKeys.PASSWORD.name],
        confirmValue
      ) ||
      ("addUser" === props.type &&
        null !== popup &&
        !validation.checkRole(
          formState[signUpKeys.DESIRED_ROLE.name],
          formState.companyId,
          setValidRole
        ))
    ) {
      return;
    }

    if ("addUser" === props.type && null !== popup) {
      const companyId = undefined !== popup.companyId ? popup.companyId : "";
      const attribute = helper.getAttributes(
        formState,
        keys,
        companyId,
        getCognitoUserRole(user)
      );
      if ("" === attribute[signUpKeys.DESIRED_ROLE.name]) {
        delete attribute[signUpKeys.DESIRED_ROLE.name];
      }
      dispatch(addNewUser(attribute, dispatch, popup.companyId, user));
    } else {
      const identity = helper.getIdentity(formState, signUpKeys);
      const attributeList = helper.getAttributesList(formState, signUpKeys);

      dispatch(loader_add());
      dispatch(register(identity, attributeList, dispatch));
    }
  };

  return (
    <div>
      <form noValidate autoComplete="off">
        <TextField
          name={signUpKeys.EMAIL.name}
          label={signUpKeys.EMAIL.label}
          onMouseUp={(e) => fillForm(e)}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          autoComplete="email"
          variant="outlined"
          fullWidth={true}
          margin="normal"
          required
          helperText={null !== validationEmail ? validationEmail : ""}
          error={null !== validationEmail ? true : false}
        />
        <TextField
          name={signUpKeys.FIRST_NAME.name}
          label={signUpKeys.FIRST_NAME.label}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          name={signUpKeys.LAST_NAME.name}
          label={signUpKeys.LAST_NAME.label}
          onMouseUp={(e) => fillForm(e)}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          required
          fullWidth={true}
          helperText={null !== validLastName ? validLastName : ""}
          error={validLastName ? true : false}
        />
        <TextField
          name={signUpKeys.NPI.name}
          label={signUpKeys.NPI.label}
          value={formState[signUpKeys.NPI.name]}
          onMouseUp={(e) => fillForm(e)}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          fullWidth={true}
          helperText={null !== validationNPI ? validationNPI : ""}
          error={null !== validationNPI ? true : false}
        />
        {("addUser" === props.type &&
          undefined !== popup.companyId &&
          "" === popup.companyId) ||
        null === popup ? (
          <TextField
            name={signUpKeys.GROUP_ID.name}
            label={
              "addUser" === props.type ? "Group Npi" : signUpKeys.GROUP_ID.label
            }
            value={formState[signUpKeys.GROUP_ID.name]}
            onChange={(e) => fillForm(e)}
            onKeyUp={(e) => fillForm(e)}
            onMouseUp={(e) => fillForm(e)}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            helperText={null !== validGroupId ? validGroupId : ""}
            error={null !== validGroupId ? true : false}
          />
        ) : null}
        {"addUser" === props.type &&
        undefined !== popup.companyId &&
        "" === popup.companyId ? (
          <TextField
            name="companyId"
            label="Company Id"
            value={formState["companyId"]}
            onChange={(e) => fillForm(e)}
            onKeyUp={(e) => fillForm(e)}
            onMouseUp={(e) => fillForm(e)}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          />
        ) : null}
        {"addUser" === props.type ? (
          <TextField
            name={signUpKeys.DESIRED_ROLE.name}
            label="Desired Role"
            select
            value={formState[signUpKeys.DESIRED_ROLE.name]}
            fullWidth={true}
            margin="normal"
            onClick={(e) => fillForm(e)}
            onChange={(e) => fillForm(e)}
            onKeyUp={(e) => fillForm(e)}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            helperText={null !== validRole ? validRole : ""}
            error={null !== validRole ? true : false}
          >
            <option value={""}></option>
            {getRole(user).map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        ) : null}
        {"addUser" === props.type && null !== companyBranches ? (
          <TextField
            label={getEditUserKeys.BIND_BRANCH.label}
            name={getEditUserKeys.BIND_BRANCH.key}
            select
            value={formState[getEditUserKeys.BIND_BRANCH.key]}
            fullWidth={true}
            margin="normal"
            onClick={(e) => fillForm(e)}
            onChange={(e) => fillForm(e)}
            onKeyUp={(e) => fillForm(e)}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option value=""></option>
            {companyBranches.map((branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.location}
              </option>
            ))}
          </TextField>
        ) : null}
        <TextField
          name={signUpKeys.PRACTICE_NAME.name}
          label={signUpKeys.PRACTICE_NAME.label}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          name={signUpKeys.STREET_ADDRESS.name}
          label={signUpKeys.STREET_ADDRESS.label}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          name={signUpKeys.CITY.name}
          label={signUpKeys.CITY.label}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          fullWidth={true}
        />
        {"addUser" === props.type ? null : (
          <TextField
            name={signUpKeys.STATE_REGION.name}
            label={signUpKeys.STATE_REGION.label}
            onChange={(e) => fillForm(e)}
            onKeyUp={(e) => fillForm(e)}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          />
        )}
        <TextField
          name={signUpKeys.POSTAL_CODE.name}
          label={signUpKeys.POSTAL_CODE.label}
          value={formState[signUpKeys.POSTAL_CODE.name]}
          onKeyUp={(e) => fillForm(e)}
          onChange={(e) => fillForm(e)}
          margin="normal"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          name={signUpKeys.MOBILE_NUMBER.name}
          label={signUpKeys.MOBILE_NUMBER.label}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          onMouseUp={(e) => fillForm(e)}
          value={formState[signUpKeys.MOBILE_NUMBER.name]}
          margin="normal"
          variant="outlined"
          required
          fullWidth={true}
          helperText={null !== validPhone ? validPhone : ""}
          error={null !== validPhone ? true : false}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+1</InputAdornment>
            ),
          }}
        />
        <TextField
          name={signUpKeys.PASSWORD.name}
          label={signUpKeys.PASSWORD.label}
          onChange={(e) => fillForm(e)}
          onKeyUp={(e) => fillForm(e)}
          onMouseUp={(e) => fillForm(e)}
          margin="normal"
          type="password"
          required
          variant="outlined"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          fullWidth={true}
          helperText={0 < validationPsw.length ? validationPsw.join("\n") : ""}
          error={0 < validationPsw.length ? true : false}
        />
        <TextField
          name={signUpKeys.CONFIRM_PSW.name}
          label={signUpKeys.CONFIRM_PSW.label}
          onChange={(e) => setConfirmValue(e.target.value)}
          onKeyUp={(e) => setConfirmValue(e.target.value)}
          onMouseUp={(e) => setConfirmValue(e.target.value)}
          margin="normal"
          type="password"
          required
          variant="outlined"
          fullWidth={true}
          helperText={
            validation.checkConfirm(
              formState[signUpKeys.PASSWORD.name],
              confirmValue
            )
              ? "Password don't match"
              : ""
          }
          error={
            validation.checkConfirm(
              formState[signUpKeys.PASSWORD.name],
              confirmValue
            )
              ? true
              : false
          }
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          {"" !== msg ? <div className="err-massage">{msg}</div> : null}
          <Box style={{ fontSize: 14 }}>
            {loader ? "Wait, please. Loading ..." : ""}
          </Box>
          <Button
            onClick={(e) => signUp()}
            variant="contained"
            style={{
              backgroundColor: "#02c9e3",
              color: "#ffffff",
            }}
          >
            Register
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default SignUpForm;
